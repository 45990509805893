import IconFile from "@components/ui/icons/IconFile";
import { parse } from "@config/theme";
import { css } from "@linaria/core";
import { rem } from "polished";
import React, { FC } from "react";
export const NoImage: FC<{
  children?: never;
}> = () => {
  return <div className={noImage}>
      <IconFile size={24} />
    </div>;
};
const noImage = parse({
  bg: "opacity",
  display: "flex",
  justifyContent: "center",
  alignItems: "center"
}, "n1qk9jgb");

require("./NoImage.linaria.module.css!=!../../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./NoImage.tsx");