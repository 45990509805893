import { parse, theme } from "@config/theme";
import { css } from "@linaria/core";
import { rem } from "polished";
export const parent = parse({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  p: "16",
  bg: "sidebar",
  width: "100%",
  position: "absolute"
}, "p1v85l48");
export const spacer = parse({
  position: "absolute",
  width: "100%"
}, "s1yhbfx5");
export const timerAnimation = parse({
  position: "absolute"
}, "txv974i");
export const link = parse({
  ml: "24"
}, "l65w6fc");
export const icon = parse({
  color: "success",
  display: "block",
  ml: "24"
}, "i1qxq7rc");
export const text = "t1sz7zf4";

require("./styles.linaria.module.css!=!../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./styles.ts");