import { Box } from "@components/Box"
import { Text } from "@components/ui/typograhpy/Text"
import {
  EnhancedImageFieldsFragment,
  EnhancedImageParameters,
  EnhancedImageParametersArtifactsDetectionRemoval,
  EnhancedImageParametersDefaultImageEnhancement,
  EnhancedImageParametersUpscalingFactor,
  EnhancedImageStatus,
} from "@generated"
import { useUpload } from "@lib/hooks/use-upload"
import { imgix } from "@lib/imgix"
import { parseError } from "@lib/parse-error"
import NextImage from "next/image"
import { FC } from "react"
import { EnhancementStatusIndicator } from "./EnhancementStatusIndicator"
import * as styles from "./ListItem.styles"
import { NoImage } from "./NoImage"

export const ListItemEnhancement: FC<EnhancedImageFieldsFragment> = ({
  status,
  errorCode,
  EnhancedImageParameters,
  Workflow,
  uploadId,
}) => {
  const enhancementDescription = getEnhancementDescription(
    EnhancedImageParameters as EnhancedImageParameters,
    Workflow
  )
  const { upload } = useUpload(uploadId)
  if (!upload) {
    return null
  }
  return (
    <li className={styles.list}>
      {upload.url ? (
        <div
          className={styles.imageContainer}
          data-is-ready={
            status === EnhancedImageStatus["Finished"] ? "" : undefined
          }
        >
          <NextImage
            src={imgix(upload?.url, { width: 100 })}
            alt=""
            width={40}
            height={40}
            objectFit="cover"
            objectPosition="cover"
          />
        </div>
      ) : (
        <NoImage />
      )}
      <div className={styles.textContainer}>
        <Text
          variant="regular"
          color={
            status === EnhancedImageStatus["Processing"] ||
            status === EnhancedImageStatus["Queued"]
              ? "shade400"
              : "white"
          }
          className={styles.clipText}
        >
          {upload?.originalFilename}
        </Text>
        {status === EnhancedImageStatus["Error"] ? (
          <Text variant="small" color="danger">
            {parseError(errorCode)}
          </Text>
        ) : (
          <Text
            variant="micro"
            color="grey"
            dangerouslySetInnerHTML={{ __html: enhancementDescription }}
          />
        )}
      </div>
      <Box display="flex" justifyContent="flex-end" mr="8">
        <EnhancementStatusIndicator status={status} uploadProgress={100} />
      </Box>
    </li>
  )
}

function getEnhancementDescription(
  parameters: EnhancedImageParameters,
  workflow: EnhancedImageFieldsFragment["Workflow"]
): string {
  let description: string[] = []
  if (workflow) {
    return `Workflow: ${workflow.name}`
  }
  if (parameters.superResolution) {
    description.push(
      `AI Upscaling: ${parameters.superResolution.toLowerCase()}`
    )
  }
  if (parameters.artifactsDetectionRemoval) {
    if (
      parameters.artifactsDetectionRemoval ===
      EnhancedImageParametersArtifactsDetectionRemoval["Auto"]
    ) {
      description.push(`AI Artifacts Detection: Auto`)
    }
    if (
      parameters.artifactsDetectionRemoval ===
      EnhancedImageParametersArtifactsDetectionRemoval["On"]
    ) {
      description.push(`AI Artifacts Detection: Enforced`)
    }
  }
  if (parameters.defaultImageEnhancement) {
    if (
      parameters.defaultImageEnhancement ===
      EnhancedImageParametersDefaultImageEnhancement["On"]
    )
      description.push(`Default image enhancement: Enabled`)
  }
  if (parameters.upscalingCustomWidth) {
    description.push(`AI Upscaling: ${parameters.upscalingCustomWidth}px wide`)
  }
  if (
    parameters.upscalingFactor &&
    parameters.upscalingFactor !==
      EnhancedImageParametersUpscalingFactor["Custom"]
  ) {
    description.push(`AI Upscaling: ${parameters.upscalingFactor}`)
  }
  if (parameters.preset) {
    description.push(`Preset: ${parameters.preset}`)
  }

  return description.join("<br />")
}
