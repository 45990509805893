export function getTransformProperties(index: number, maxHeight: number) {
  if (index === 0) {
    return undefined
  }

  const y = `calc(-${maxHeight - 10}px + 100% + -${10 + index * 10}px)`
  const z = `-${index}px`
  const scale = 1 - index * 0.05

  return `translate3d(0px, ${y}, ${z}) scale(${scale})`
}
