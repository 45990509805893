import { parse } from "@config/theme";
import { css } from "@linaria/core";
import { rem } from "polished";
export const parent = parse({
  position: "absolute",
  display: "flex",
  justifyContent: "center"
}, "p1ky56d7");
export const toastContainer = parse({
  position: "relative"
}, "t1tulau5");

require("./Toasts.styles.linaria.module.css!=!../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./Toasts.styles.ts");