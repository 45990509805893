import React, { FC, HTMLAttributes } from "react"

type LoadingPulseProps = Pick<HTMLAttributes<HTMLOrSVGElement>, "className"> & {
  size?: number
}

export const LoadingPulse: FC<LoadingPulseProps> = ({
  size = 8,
  className,
}) => {
  return (
    <svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 8 8"
      className={className}
    >
      <circle cx="4" cy="4" r="4" fillOpacity="0.3" fill="#fff">
        <animate
          attributeName="fill-opacity"
          begin="0s"
          calcMode="linear"
          dur=".8s"
          from="0.1"
          repeatCount="indefinite"
          to="0.1"
          values=".1;1;.1"
        ></animate>
      </circle>
    </svg>
  )
}
