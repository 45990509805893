import IconCheck from "@components/ui/icons/IconCheck"
import { Link } from "@components/ui/link"
import { Text } from "@components/ui/typograhpy/Text"
import { useDispatch, useToasts } from "@lib/store"
import { m, useMotionValue, useTransform } from "framer-motion"
import { FC, useEffect, useRef } from "react"
import { getTransformProperties } from "./helpers/get-transform-properties"
import * as styles from "./styles"

export type ToastActionVariant = "progress-modal" | "progress-modal-errors"

type ToastWithAction = {
  buttonAction?: ToastActionVariant
  buttonText?: string
  handleClickButton?: () => void
}

type ToastWithoutAction = {
  buttonAction?: never
  buttonText?: never
  handleClickButton?: never
}

type ToastVariant = "success" | "info" | "error"

type ToastType = ToastWithoutAction | ToastWithAction

export type ToastProps = ToastType & {
  variant: ToastVariant
  title: string
  handleRequestClose: () => void
  children?: never
  index: number
  isFirstVisible: boolean
}

export const Toast: FC<ToastProps> = ({
  variant,
  title,
  handleRequestClose,
  handleClickButton,
  buttonAction,
  buttonText,
  isFirstVisible,
  index,
}) => {
  const ref = useRef<HTMLDivElement>(null)
  const { maxHeight } = useToasts()
  const dispatch = useDispatch()
  const x = useMotionValue(0)
  const opacity = useTransform(x, [-100, -50, 50, 100], [1, 1, 1, 0])

  const transform = getTransformProperties(index, maxHeight)

  useEffect(() => {
    //
    // We need the height of the frontmost toast to calculate the transformations applied to other visible toasts
    //
    if (isFirstVisible && ref.current) {
      const { height } = ref.current.getBoundingClientRect()
      dispatch({ type: "set-max-height-toast", maxHeight: height })
    }
  }, [isFirstVisible, ref, dispatch])

  return (
    <m.div
      layout
      drag="x"
      dragConstraints={{ left: 0, right: 100 }}
      dragElastic={0.05}
      onDragEnd={(_, info) => handleDragEnd(info.velocity.x)}
      dragSnapToOrigin
      style={{
        position: "relative",
        zIndex: 100 - index,
        opacity,
        x,
      }}
    >
      <div
        ref={ref}
        className={styles.parent}
        style={{
          transform,
          maxHeight: index > 0 ? maxHeight : undefined,
          visibility: index > 2 ? "hidden" : "visible",
        }}
        data-toast-element
        data-hide-children={index > 0 ? "" : undefined}
      >
        <span
          data-toast-countdown-animation
          className={styles.timerAnimation}
          data-variant={variant}
          onAnimationEnd={handleRequestClose}
        />
        <span className={styles.spacer} />
        <Text variant="regular" fontWeight="semiBold" className={styles.text}>
          {title}
        </Text>
        {buttonAction && buttonText && handleClickButton && (
          <Link
            size="small"
            onClick={handleClickButton}
            className={styles.link}
          >
            {buttonText}
          </Link>
        )}
        {variant === "success" && !buttonAction && (
          <span className={styles.icon}>
            <IconCheck size={20} />
          </span>
        )}
      </div>
    </m.div>
  )

  function handleDragEnd(velocityX: number) {
    if (velocityX > 150) {
      handleRequestClose()
    }
  }
}
