import { EnhancedImageFieldsFragment } from "@generated"
import { getGraphqlClient } from "@lib/graphql-client"
import { useMemo } from "react"
import { useQuery, useQueryClient } from "react-query"

const { sdk } = getGraphqlClient()
export function useTasksList() {
  const { data } = useQuery("tasks-list", () => sdk.TasksList(), {})
  const client = useQueryClient()

  const hash = data?.tasksList?.hash
  const { inProgress, completed } = useMemo(() => {
    const inProgress =
      (data?.tasksList?.inProgress as EnhancedImageFieldsFragment[]) ?? []
    const completed =
      (data?.tasksList?.completed as EnhancedImageFieldsFragment[]) ?? []

    for (const item of completed) {
      client.invalidateQueries(["upload", item.uploadId])
    }

    return { inProgress, completed }
    // The server will return a hash of the tasks list, with we can use to detect if something changed
  }, [hash]) // eslint-disable-line react-hooks/exhaustive-deps

  return {
    inProgress,
    completed,
  }
}
