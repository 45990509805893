import { Span } from "@components/ui/typograhpy/Span";
import { parse, theme } from "@config/theme";
import { css } from "@linaria/core";
import React, { FC } from "react";
import { TasksListDialogStatus } from ".";
type SelectStateProps = {
  handleChange: (value: TasksListDialogStatus) => void;
  value: TasksListDialogStatus;
};
export const SelectState: FC<SelectStateProps> = ({
  handleChange,
  value
}) => {
  return <div className={parent}>
      <button className={button} disabled={value === "enhancements"} onClick={() => handleChange("enhancements")}>
        <Span mb="12" display="block" variant="button-label-big">
          Enhancements
          {value === "enhancements" && <span className={line} />}
        </Span>
      </button>
      <button className={button} disabled={value === "uploads"} onClick={() => handleChange("uploads")}>
        <Span mb="12" display="block" variant="button-label-big">
          Uploads
          {value === "uploads" && <span className={line} />}
        </Span>
      </button>
    </div>;
};
const parent = parse({
  display: "flex",
  width: "100%",
  mb: "24"
}, "p19lobns");
const button = parse({
  textAlign: "left",
  mr: "24",
  color: "shade500",
  position: "relative"
}, "bjgg96m");
const line = parse({
  position: "absolute",
  width: "100%",
  bg: "white",
  display: "block"
}, "ljafl2k");

require("./SelectState.linaria.module.css!=!../../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./SelectState.tsx");