import { ModalOrSidebar } from "@components/ui/modal-sidebar";
import { parse, theme } from "@config/theme";
import { EnhancedImageStatus } from "@generated";
import { useTasksList } from "@lib/hooks/use-tasks-list";
import { useStore } from "@lib/store";
import { css } from "@linaria/core";
import { FC, useEffect, useState } from "react";
import { TaskListDialogHeading } from "./Heading";
import { ListItemEnhancement } from "./ListItemEnhancement";
import { ListItemUpload } from "./ListItemUpload";
import { SelectState } from "./SelectState";
type TasksListDialogProps = {
  open: boolean;
  initialStatus?: TasksListDialogStatus;
  onOpenChange: (open: boolean) => void;
};
export type TasksListDialogStatus = "uploads" | "enhancements";
export const TasksListDialog: FC<TasksListDialogProps> = ({
  open,
  onOpenChange,
  initialStatus = "enhancements"
}) => {
  const [status, setStatus] = useState<TasksListDialogStatus>(initialStatus);
  const uploads = useStore(state => state.uploads.byId.map(id => state.uploads.items[id]));
  const {
    completed,
    inProgress
  } = useTasksList();
  const queued = inProgress.filter(item => item.status === EnhancedImageStatus.Queued);
  const processing = inProgress.filter(item => item.status === EnhancedImageStatus.Processing);
  const enhancements = [...processing, ...queued, ...completed];
  useEffect(() => {
    setStatus(initialStatus);
  }, [initialStatus]);
  return <ModalOrSidebar title="Processing" open={open} onOpenChange={onOpenChange}
  // stickyFooter={
  //   showClearErrors ? (
  //     <ClearErroredTasks erroredTasks={erroredTasks} />
  //   ) : undefined
  // }
  >
      <SelectState handleChange={val => setStatus(val)} value={status} />
      <ul className={list}
    // data-extra-padding-bottom={showClearErrors ? "" : undefined}
    >
        <TaskListDialogHeading count={status === "enhancements" ? enhancements.length : uploads.length} />
        {status === "enhancements" && enhancements.map(item => item ? <ListItemEnhancement key={item.id} {...(item as any)} /> : null)}
        {status === "uploads" && uploads.map(item => <ListItemUpload key={item.id} {...item} />)}
      </ul>
    </ModalOrSidebar>;
};
const list = parse({
  position: "relative"
}, "l1h9y9jn");

require("./index.linaria.module.css!=!../../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./index.tsx");