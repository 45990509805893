import React, { FC } from "react"
import { Icon, IconProps } from "./Icon"

export const IconFile: FC<IconProps> = (props) => {
  return (
    <Icon {...props}>
      <path
        d="M23 13.5V25C23 25.5523 22.5523 26 22 26H10C9.44772 26 9 25.5523 9 25V7C9 6.44772 9.44772 6 10 6H16M23 13.5L16 6M23 13.5H17C16.4477 13.5 16 13.0523 16 12.5V6"
        stroke="currentColor"
        strokeWidth="1.5"
      />
    </Icon>
  )
}

export default IconFile
