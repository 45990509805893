import IconCheck from "@components/ui/icons/IconCheck"
import { Text } from "@components/ui/typograhpy/Text"
import { parse } from "@config/theme"
import React, { FC } from "react"

type StatusFinishedProps = {
  children: string
}

export const StatusFinished: FC<StatusFinishedProps> = ({ children }) => {
  return (
    <Text variant="micro" color="success" className={text}>
      <IconCheck size={12} color="inherit" className={icon} />
      {children}
    </Text>
  )
}

const text = parse({ display: "flex", alignItems: "center" })
const icon = parse({ mr: "4" })
