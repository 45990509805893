import { parse, theme } from "@config/theme";
import { css } from "@linaria/core";
import { rem, rgba } from "polished";
export const list = parse({
  display: "grid",
  alignItems: "center",
  mb: "20"
}, "l1pua51j");
export const textContainer = parse({
  width: "100%"
}, "t7femq7");
export const imageContainer = parse({
  position: "relative"
}, "il423m2");
export const clipText = "ctqoys3";

require("./ListItem.styles.linaria.module.css!=!../../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./ListItem.styles.ts");