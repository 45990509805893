import { Text } from "@components/ui/typograhpy/Text"
import React, { VFC } from "react"

type TaskListDialogHeadingProps = {
  count: number
}

export const TaskListDialogHeading: VFC<TaskListDialogHeadingProps> = ({
  count,
}) => {
  if (!count) {
    return (
      <Text mb="8" variant="regular" color="shade400">
        Nothing to see here!
      </Text>
    )
  }
  return (
    <Text mb="8" variant="micro" color="shade400">
      {count} {count === 1 ? " photo" : " photos"}
    </Text>
  )
}
