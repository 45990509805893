import { UploadFieldsFragment } from "@generated"
import { getGraphqlClient } from "@lib/graphql-client"
import { useQuery } from "react-query"

const { sdk } = getGraphqlClient()

export type Upload = {
  status: "idle" | "enhancing"
} & UploadFieldsFragment

export function useUpload(uploadId: string): {
  upload: Upload | null
  refetch: () => void
} {
  const { data, error, refetch } = useQuery<Upload>(
    ["upload", uploadId],
    async () => {
      const upload = (await sdk.Upload({ id: uploadId })).upload
      return {
        ...(upload as UploadFieldsFragment),
        status: "idle",
      }
    },
    {
      // This is very important, it will make sure that the query is never refetched (i.e. on focus or rerender)
      // That could cause the status property being overwritten on a rerender
      staleTime: Infinity,
    }
  )

  if (process.env.NODE_ENV === "development" && error) {
    console.error(error)
  }
  return {
    upload: data ?? null,
    refetch,
  }
}
