import React, { FC } from "react"
import { Icon, IconProps } from "./Icon"

export const IconDanger: FC<IconProps> = (props) => {
  return (
    <Icon {...props}>
      <path
        d="M16 13.5V17.25M16 21H16.0123M7.46695 26H24.533C26.4292 26 27.6144 23.9166 26.6663 22.25L18.1333 7.25C17.1852 5.58333 14.8149 5.58333 13.8668 7.25L5.33369 22.25C4.38558 23.9166 5.57072 26 7.46695 26Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  )
}

export default IconDanger
